<template>
    <v-tabs grow>
        <v-tab>
            <v-icon left>
            mdi-upload
            </v-icon>
            Archivivos
        </v-tab>
        <!--
        <v-tab>
            <v-icon left>
            mdi-book
            </v-icon>
            Formularios
        </v-tab>
        -->
        <v-tab-item>
            <v-row class="mx-2">
                <v-col cols="12" sm="3">
                    <Lista 
                        @seleccion="elemento_lista_seleccionado($event)"
                        @crear_carpeta="crear_carpeta()"
                        :carpeta_abierta="posicion_nueva" 
                    />
                </v-col>
                <v-col cols="12" sm="9" >
                    <v-row>
                        <ToolbarMultiIcons 
                            :titulo="'Archivos de: '+$store.state.buscador.carpetas[posicion].nombre" 
                            :activar_tool="true"
                            
                            :actions="[{icon: 'mdi-file-document-multiple-outline', name: 'Agregar archivos', value:'upload_files'},
                                       {icon: 'mdi-download', name: 'Descargar archivos', value:'download_files'},
                                       {icon: 'mdi-pencil', name: 'Editar carpeta', value: 'edit_folder'},
                                       {icon: 'mdi-delete', name: 'Eliminar carpeta', value: 'delete_folder'},
                                       
                                       
                                       ]"
                            @upload_files="dialogo_agregar_archivos_carpeta=true"
                            @download_files="download_files(archivos)"
                            @edit_folder="dialogo_edit_folder=true"
                            @delete_folder="dialogo_delete_folder=true"

                        />
                    </v-row>
                    <v-row>
                        <v-col v-if="$store.state.buscador.carpetas[posicion].archivos.length > 0">
                                <v-row>
                                    <v-col class="mx-auto" cols="12" sm="8">
                                        <Paginacion :cantidad_paginas="archivos.length" class="my-3" @buscar="pagina=$event-1" />
                                    </v-col>
                                </v-row>
                               
                                <v-row class="mx-5">
                                    <v-col>
                                        <v-select filled rounded dense label="Filtrar por" v-model="parametro_busqueda" 
                                        return-object item-text="name" 
                                        :items="parametros_busqueda_array"></v-select>
                                    </v-col>
                                    <v-col>
                                        <v-text-field label="Buscar" filled rounded dense v-model="search" prepend-inner-icon="mdi-magnify"></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <v-window v-model="pagina">
                                            <v-window-item v-for="(archivo, k) in archivos" :key="k"  >
                                                <v-sheet
                                                    class="mx-auto"
                                                    max-width="75%"
                                                    color=" "
                                                    elevation=""
                                                >
                                                    <v-container>
                                                    <v-row>
                                                        <v-col>
                                                        <Archivos
                                                            :mostrar_carpeta_publica="true"
                                                            :archivo="archivo"
                                                        
                                                            :width="800"
                                                            :height="500"
                                                        />
                                                        </v-col>
                                                    </v-row>
                                                    </v-container>
                                                </v-sheet>
                                            </v-window-item>
                                        </v-window>
                                    
                                    </v-col>
                                </v-row>
                        </v-col>
                        <v-col v-else>
                            <NotFound :mensaje="'No hay archivos subidos'" />
                        </v-col>
                    </v-row>
                   
                </v-col>
                
            </v-row>
            <v-dialog v-model="dialogo_agregar_archivos_carpeta" max-width="600" persistent >
                <AgregarArchivos 
                    :carpeta_actual="posicion" 
                    :carpetas="$store.state.buscador.carpetas" 
                    @cerrar="cerrar_dialogo_agregar_archivos()"
                />
            </v-dialog>
            <v-dialog v-model="dialogo_crear_carpeta" persistent max-width="600" >
                <Card :titulo="'Crear carpeta'" @activar="cerrar_dialogo_crear_carpeta()" >
                    <template v-slot:contenido>
                        <v-form ref="form" v-model="valid">
                            <v-row>
                                <v-col>
                                    <v-text-field :rules="[
                                            (v) => !!v || 'Es requerido asignar un nombre para la carpeta',
                                            ]" required 
                                        v-model="$store.state.buscador.carpetas[posicion_nueva].nombre" filled rounded dense label="Nombre carpeta"></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12">
                                    <v-row v-for="(meta_dato,index) in $store.state.buscador.carpetas[posicion_nueva].meta_datos" :key="index" >
                                        <v-text-field
                                            :rules="[
                                            (v) => !!v || 'Es requerido asignar un nombre al campo',
                                            ]"
                                            required
                                            v-model="meta_dato.name"
                                            rounded
                                            dense
                                            filled
                                            prepend-icon="mdi-book"
                                            label="Nombre campo"
                                            :append-outer-icon="'mdi-delete'"
                                            @click:append-outer="meta_dato.splice(index, 1)"
                                        ></v-text-field>

                                    </v-row>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    
                                    <v-btn @click="agregar_meta_dato_nueva_carpeta()" dark color="green" > Agregar meta dato </v-btn>
                                    
                                </v-col>
                                <v-spacer></v-spacer>
                                <v-col class="">
                                     <v-btn @click="crear_carpeta_endpoint()" :disabled="!valid" style="color:white" color="indigo darken-4">Crear nueva carpeta </v-btn>
                                </v-col>
                               
                            </v-row>
                        </v-form>
                    </template>
                </Card>
            </v-dialog>
            <v-dialog v-model="dialogo_edit_folder" max-width="500">
                <Card :titulo="'Editar carpeta '+$store.state.buscador.carpetas[posicion].nombre" @activar="dialogo_edit_folder=false" >
                    <template v-slot:contenido>
                        
                        <v-card-text>
                            <v-text-field filled rounded dense label="Nombre de la carpeta" v-model="name_folder" ></v-text-field>
                        </v-card-text>
                        <v-card-actions>
                            <v-btn @click="edit_name_folder(name_folder)" 
                                    block dark 
                                    color="green"
                            > 
                                Editar <v-icon> mdi-pencil </v-icon>
                            </v-btn>
                        </v-card-actions>
                    </template>
                </Card>
            </v-dialog>
            <v-dialog v-model="dialogo_delete_folder" max-width="500" >
                <Card :titulo="'Eliminar carpeta'" :color="'red'" @activar="dialogo_delete_folder=false" >
                    <template v-slot:contenido  >
                        <v-divider class="my-3"></v-divider>
                        <h2 style="text-align:center"> Estás seguro que deseas eliminar la carpeta: {{$store.state.buscador.carpetas[posicion].nombre}} </h2>
                        <v-divider class="my-3"></v-divider>
                        <v-card-actions>
                            <v-btn block color="red" dark @click="delete_folder()" > Eliminar <v-icon>mdi-delete</v-icon> </v-btn>
                        </v-card-actions>
                    </template>
                </Card>
            </v-dialog>
        </v-tab-item>
            <!-- Listado de formularios enviados por los empleados a este supervisor-->
        <!--
        <v-tab-item>
            <v-window v-model="pagina_formularios" class="" v-if="$store.state.buscador.formularios.length>0">
                <v-row>
                    <v-col cols="6">
                    <v-btn block text color="red" @click="pagina_formularios--"> Anterior </v-btn>
                    </v-col>
                    <v-col cols="6">
                    <v-btn block text color="indigo" @click="pagina_formularios++"> Siguiente </v-btn>
                    </v-col>
                </v-row>
                <v-window-item v-for="(formulario,m) in $store.state.buscador.formularios" :key=m>
                    <v-card>
                        <v-card-text>
                        
                        <v-form>
                            <v-row>
                            <v-col>
                                <h3 style="text-align:center"> Digitalizado por: {{formulario.autor}} </h3>
                            </v-col>
                            </v-row>
                            <v-row>
                                <v-col class="mx-auto" cols="12" sm="4" md="3" v-for="(input,n) in formulario.parametros" :key="n">
                                <v-text-field readonly rounded filled dense :label="input.name" v-model="input.value" ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                        
                            </v-row>
                        </v-form>
                        </v-card-text>
                    </v-card>
                </v-window-item>
            </v-window>
            <v-row v-else>
                <v-col>
                    <NotFound :mensaje="'No hay formularios subidos'" />
                </v-col>
            </v-row>
        </v-tab-item>
        -->
    </v-tabs>   
</template>
<script>
import Lista from './Lista.vue'
import Archivos from '../Archivos.vue'
import AgregarArchivos from '../AgregarArchivos.vue'
import { mapState } from 'vuex';
import {EventBus} from '../../event/event-bus'
import ToolbarMultiIcons from '../../components/atomos/ToolbarMultiIcons.vue'
import folders from '../../mixins/folders'
import  jsPDF  from "jspdf";
export default {
    mixins: [folders],
    components: {
        Lista,
         Archivos,
         AgregarArchivos,
         ToolbarMultiIcons,
    },
    data(){
        return {

            search: "",
            parametro_busqueda: null,
            parametros_busqueda_array: [],

            archivos: [],
            
            dialogo_agregar_archivos_carpeta: false,

            pagina: 0, //Indica el archivo de la carpeta la cual se esta viendo en el componente Archivos
            pagina_formularios: 0,
            
            posicion: 0, 
            //Indica la posicion o carpeta del array en la cual estamos posicionados
            //Para ver las imagenes o agregar documentos

            dialogo_crear_carpeta: false,
            posicion_nueva: 0,
            //Indicara la posicion del array en el cual iran los datos de la nueva carpeta cuando se clikee
            //en el boton  de crear carpeta


            valid: true,
            ruta_archivos: 'https://drive.educandote.co/audidoc',
            dialogo_edit_folder: false,
            name_folder: "",
            dialogo_delete_folder: false,
            load_create_folder: false
        }
    },
    created(){
        try {
            this.archivos = this.$store.state.buscador.carpetas[0].archivos
            this.parametros_busqueda_array = []
        //console.log(this.$store.state.buscador.carpetas[2].archivos[0].meta_datos)
        } catch (error) {
            console.log(error)
        }
        
    },
    mounted(){
        EventBus.$on('cambiar_carpeta', (payload) => {
            console.log(payload)
            this.posicion = payload
        })
    },
    computed: mapState('buscador',['carpetas']),
    watch: {
        carpetas(val){
            this.archivos = [...val[0].archivos]
            console.log(val)
        },

        posicion(val){
           
            this.archivos = this.$store.state.buscador.carpetas[val].archivos
            this.parametros_busqueda_array = []
            for (let index = 0; index < this.$store.state.buscador.carpetas[val].archivos[0].meta_datos.length; index++) {
               this.parametros_busqueda_array.push({
                   name: this.$store.state.buscador.carpetas[val].archivos[0].meta_datos[index].name,
                   key: this.$store.state.buscador.carpetas[val].archivos[0].meta_datos[index].key
               })
                
            }
              console.log(this.parametros_busqueda_array)
        },
        search (val) {
          
          this.pagina = 0
          if(val==""){
              this.archivos = [...this.$store.state.buscador.carpetas[this.posicion].archivos]
             
          }else{
              if(this.parametro_busqueda!=null){
                    let index = this.$store.state.buscador.carpetas[this.posicion].archivos[0].meta_datos.indexOf(this.parametro_busqueda)  
                    console.log(index)
                    val = val.toLowerCase()
                    let aux = []
                    for (let index = 0; index < this.$store.state.buscador.carpetas[this.posicion].archivos.length; index++) {
                    
                    for (let index2 = 0; index2 < this.$store.state.buscador.carpetas[this.posicion].archivos[index].meta_datos.length; index2++) {
                        
                        if(this.$store.state.buscador.carpetas[this.posicion].archivos[index].meta_datos[index2].key==this.parametro_busqueda.key){
                            if(this.$store.state.buscador.carpetas[this.posicion].archivos[index].meta_datos[index2].value.toLowerCase()==val.toLowerCase())
                            {
                                aux.push(this.$store.state.buscador.carpetas[this.posicion].archivos[index])
                            }
                        }
                    }
                        
                    }
                    console.log(aux)
                    this.archivos = [...aux]
                    console.log(this.archivos)
                } 
          }
          console.log(this.$store.state.buscador.carpetas[this.posicion].archivos)
          console.log(this.parametro_busqueda)

             
        },
    },
    methods: {
        download_files(files){
            this.$store.state.dialogCargando = true
            let no_image_files = []
           
           try {
               console.log('descargar')
                console.log(files)
                var doc = new jsPDF('p', 'mm','a4');
                let altura = 0
                for (let index = 0; index < files.length; index++) {
                    if(files[index].tipo.toLowerCase()=='blob'||files[index].tipo.toLowerCase()=='jpg'||files[index].tipo.toLowerCase()=='jpeg'||files[index].tipo.toLowerCase()=='png'||files[index].tipo.toLowerCase()=='bmp'){
                        doc.addImage(
                        this.urlRespuestas(
                            files[index],
                            this.ruta_archivos
                            )
                        ,files[index].tipo ,0, altura,200 ,280
                        )

                        //Evita que se inserte una pagina en blanco al final 
                        if(index+1 != files.length ){
                            doc.addPage('a4','p')
                        }
                    }else{
                        no_image_files.push({page:index+1})
                    }
                    
                        
                }
                if(no_image_files.length>0){
                    let message = "";
                    for (let index = 0; index < no_image_files.length; index++) {
                        message = message + " "+ no_image_files[index].page
                        
                    }
                    this.$store.commit(
                                    "error",
                                    "Los archivos de las paginas: "+message+ " No corresponden a imagenes y no pueden ser descargadas de forma automatica, por favor descarguelos de forma manual"
                                );
                }else{
                    doc.save('Carpeta.pdf');
                    this.$store.commit(
                                    "exitoso",
                                    "Los archivos han sido descargador de forma exitosa"
                                ); 
                }   
           } catch (error) {
               console.log(error)
               this.$store.commit(
                    "error",
                    "Ha ocurrido un error inesperado en el momento de descargar una imagn "+error.message
                );
           }
           
           this.$store.state.dialogCargando = false
        },
        elemento_lista_seleccionado(elemento){
            console.log(elemento)
            
            console.log('xd')
            let index = this.$store.state.buscador.carpetas.findIndex( (expediente) => expediente.nombre == elemento.nombre  )

            console.log(index)
            this.posicion = index
            
        },
        cerrar_dialogo_agregar_archivos(){
            this.dialogo_agregar_archivos_carpeta=false
            this.archivos=this.$store.state.buscador.carpetas[this.posicion].archivos
        },
        cerrar_dialogo_crear_carpeta(){
            this.posicion_nueva = 0
            this.dialogo_crear_carpeta=false
            this.$store.state.buscador.carpetas.pop()
        },
        crear_carpeta(){
           
            this.$store.state.buscador.carpetas.push({
                nombre: "",
                meta_datos: [
                    {
                        id: Date.parse(new Date())+Math.random(),
                        key: "",
                        name: "",
                        type: "string",
                        value: "",
                    }
                ],
                archivos: [],
                //id: Date.parse(new Date())+Math.random(), Nunca puse el Id por consiguiente los filtro
                //Por el nombre
                estado: 'habilitada'
            })
            this.dialogo_crear_carpeta=true
            this.posicion_nueva = this.$store.state.buscador.carpetas.length-1
            try {
                this.$refs.form.resetValidation()
            } catch (error) {
                console.log(error)
            }
           
        },
        async edit_name_folder(name_folder){

            const url = "https://audidoc.educandote.co/types/collections/"+this.$store.state.buscador.coleccion_archivo_seleccionado+"/documents/"+ this.$store.state.buscador.archivo_seleccionado.id
            const get_folders = await this.$store.dispatch('llamado_get',{
                url: url,
                tipo_header: "indexador"
            });
            console.log(get_folders.data);

            let refresh_folders = await this.refresh_folders(get_folders.data.carpetas); //mixin folders
            let carpetas = [...refresh_folders];
            try {
                console.log(name_folder);    
                this.$store.state.buscador.carpetas[this.posicion].nombre = name_folder;
                carpetas[this.posicion].nombre = name_folder;
                for (let index = 0; index < carpetas.length; index++) {
                   carpetas[index] = JSON.stringify(carpetas[index])
                }
                console.log(carpetas)

               let response = await this.$store.dispatch('llamado_patch',{
                    url: url,
                    body:{
                        carpetas: carpetas
                    },
                    tipo_header: "indexador",
                })
                console.log(response)

                console.log(this.$store.state.buscador.carpetas[this.posicion])
                this.$store.commit("exitoso","La carpeta ha sido editada de forma exitosa");
                this.name_folder = "";
                
            } catch (error) {
                console.log(error);
                this.$store.commit("error","Algo ha salido mal creando la carpeta "+error.message);
            }
            this.dialogo_edit_folder = false;
        },
        async delete_folder(){
            const url = "https://audidoc.educandote.co/types/collections/"+this.$store.state.buscador.coleccion_archivo_seleccionado+"/documents/"+ this.$store.state.buscador.archivo_seleccionado.id
            const get_folders = await this.$store.dispatch('llamado_get',{
                url: url,
                tipo_header: "indexador"
            });
            console.log(get_folders.data);

            let refresh_folders = await this.refresh_folders(get_folders.data.carpetas); //mixin folders
            let carpetas = [...refresh_folders];
            try {
                
                carpetas[this.posicion].estado = 'inhabilitada';
                this.$store.state.buscador.carpetas[this.posicion].estado = 'inhabilitada';
                for (let index = 0; index < carpetas.length; index++) {
                   carpetas[index] = JSON.stringify(carpetas[index])
                }
                console.log(carpetas)

               let response = await this.$store.dispatch('llamado_patch',{
                    url:url,
                    body:{
                        carpetas: carpetas
                    },
                    tipo_header: "indexador",
                })
                console.log(response)

                console.log(this.$store.state.buscador.carpetas[this.posicion])
                this.$store.commit("exitoso","La carpeta ha sido eliminada de forma exitosa");
                
            } catch (error) {
                console.log(error);
                this.$store.commit("error","Algo ha salido mal eliminando la carpeta "+error.message);
            }
            this.dialogo_delete_folder = false;
        },
        agregar_meta_dato_nueva_carpeta(){
            this.$store.state.buscador.carpetas[this.posicion_nueva].meta_datos.push(
                {
                     id: Date.parse(new Date())+Math.random(),
                     key: "",
                     name: "",
                     type: "string",
                     value: "",
                }
            )
        },
        async crear_carpeta_endpoint(){
            this.load_create_folder = true
            try {
                
                console.log(this.$store.state.buscador.carpetas)
                console.log(this.$store.state.buscador.carpetas[this.posicion_nueva])
                console.log(this.posicion_nueva)
              
                for (let index = 0; index < this.$store.state.buscador.carpetas[this.posicion_nueva].meta_datos.length; index++) {
                    this.$store.state.buscador.carpetas[this.posicion_nueva].meta_datos[index].key = 
                    this.$store.state.buscador.carpetas[this.posicion_nueva].meta_datos[index].name.replace(/ /g, "_")
                }
                /*
                let carpetas = [...this.$store.state.buscador.carpetas]
                
                for (let index = 0; index < carpetas.length; index++) {
                   carpetas[index] = JSON.stringify(carpetas[index])
                }
                */
                
                const url = "https://audidoc.educandote.co/types/collections/"+this.$store.state.buscador.coleccion_archivo_seleccionado+"/documents/"+ this.$store.state.buscador.archivo_seleccionado.id
                const get_folders = await this.$store.dispatch('llamado_get',{
                    url: url,
                    tipo_header: "indexador"
                });
                console.log(get_folders.data);
                let carpetas = [...get_folders.data.carpetas];
                console.log(carpetas)
                let new_folder = JSON.stringify(this.$store.state.buscador.carpetas[this.posicion_nueva])

                if(carpetas[carpetas.length-1]!=new_folder){
                    carpetas.push(new_folder);
                }

                
                console.log(carpetas);


               let response = await this.$store.dispatch('llamado_patch',{
                    url: url,
                    body:{
                        carpetas: carpetas
                    },
                    tipo_header: "indexador",
                })
                console.log(response)
              
                let refresh_folders = await this.refresh_folders(carpetas);
                

                this.$store.state.buscador.carpetas = [...refresh_folders];

                //console.log(this.$store.state.buscador.carpetas[this.posicion_nueva].meta_datos)
                this.$store.commit("exitoso","La carpeta ha sido creada de forma exitosa");
                this.posicion = this.$store.state.buscador.carpetas.length-1
            } catch (error) {
                console.log(error)
                this.$store.commit(
                    "error",
                    "Algo ha salido mal creando la carpeta "+error.message
                );
                this.$store.state.buscador.carpetas.pop()
            }
            this.load_create_folder = false
            this.dialogo_crear_carpeta = false
        }
    }
}
</script>