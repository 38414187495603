var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('v-app-bar',{attrs:{"relative":"","color":"#6A76AB","dark":"","src":"https://picsum.photos/1920/1080?random","fade-img-on-scroll":"","scroll-target":"#scrolling-techniques-4"},scopedSlots:_vm._u([{key:"img",fn:function(ref){
var props = ref.props;
return [_c('v-img',_vm._b({attrs:{"gradient":"to top right, rgba(0,0,0), rgba(25,32,72,.7)"}},'v-img',props,false))]}}])},[_c('v-app-bar-nav-icon',{on:{"click":function($event){_vm.drawer = true}}}),_c('v-toolbar-title',[_vm._v("AUDIDOC")]),_c('v-spacer'),_c('v-btn',{attrs:{"fab":"","text":"","small":""},on:{"click":function($event){_vm.$vuetify.theme.dark = !_vm.$vuetify.theme.dark}}},[_c('v-icon',[_vm._v(" mdi-brightness-4 ")])],1),_c('v-btn',{attrs:{"text":"","fab":"","small":""}},[_c('Perfil')],1),(_vm.permiso_buscador)?_c('v-dialog',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-tooltip',_vm._g(_vm._b({scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","fab":"","text":"","color":"white darken-4"},on:{"click":function($event){_vm.buscador = !_vm.buscador}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-clipboard-text-search")])],1)]}}],null,true)},'v-tooltip',attrs,false),on),[_c('span',[_vm._v("Buscador")])])]}}],null,false,903973789),model:{value:(_vm.buscador),callback:function ($$v) {_vm.buscador=$$v},expression:"buscador"}},[_c('Buscador',[_c('template',{slot:"accion"},[_c('v-btn',{attrs:{"icon":"","fab":"","text":"","small":""},on:{"click":function($event){_vm.buscador = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],2)],1):_vm._e(),_c('v-btn',{attrs:{"color":"red","icon":""}},[_c('v-divider',{staticClass:"mr-5",attrs:{"vertical":""}}),_c('v-icon',{on:{"click":function($event){return _vm.logout()}}},[_vm._v("mdi-logout")])],1)],1),_c('v-main',[_c('router-view')],1),_c('v-navigation-drawer',{attrs:{"absolute":"","temporary":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-list',{attrs:{"nav":"","dense":""}},[_c('v-list-item-group',{attrs:{"active-class":"black--text text--accent-4"},model:{value:(_vm.group),callback:function ($$v) {_vm.group=$$v},expression:"group"}},_vm._l((_vm.rutas_disponibles),function(permiso,index){return _c('v-list-item',{key:index,on:{"click":function($event){return _vm.$router.push(permiso.permiso.ruta)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(permiso.permiso.icon))])],1),_c('v-list-item-title',[_vm._v(_vm._s(permiso.permiso.nombre))])],1)}),1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }