<template>
    <Card :titulo="'Agregar archivos carpeta: '+$store.state.buscador.carpetas[carpeta_actual].nombre" @activar="$emit('cerrar')">
        <template v-slot:contenido>
            <v-row class="mt-5">
                <v-select v-model="tipo_upload" label="Tipo de digitalizacion" filled rounded dense 
                          :items="['Escaner','Subir archivos']"
                           :append-outer-icon="tipo_upload=='Escaner' ? 'mdi-reload': null "
                           @click:append-outer="getImages()"
                >
                </v-select> 
            </v-row>
            <v-divider></v-divider>
            <h4>Meta datos de la carpeta: {{$store.state.buscador.carpetas[carpeta_actual].nombre}} </h4>
            <v-row>
                
                <v-col class="mx-auto" cols="12" sm="6" xl="4" v-for="(input, index) in $store.state.buscador.carpetas[carpeta_actual].meta_datos" :key="index">
                    <v-text-field prepend-icon="mdi-book" filled dense rounded v-model="input.value" :label="input.name"></v-text-field>
                </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row>
                <v-col>
                    <v-sheet v-if="tipo_upload=='Escaner'">
                        <Carousel v-if="imagenes.length>0" @eliminar="eliminar_archivo($event)" class="mb-5" :imagenes="imagenes" />
                        <v-btn style="color:white" color="indigo" block 
                            :disabled="imagenes.length==0" 
                            @click="terminarProcesoArchivador()"
                            >
                            Guardar imagenes  
                        </v-btn>
                    </v-sheet>
                    <v-sheet v-else-if="tipo_upload=='Subir archivos'">
                            <Drop
                                :permitir="'nop'"
                                v-on:info="pruebaDrop"
                            />
                    </v-sheet>
                </v-col>
            </v-row>
        </template>
    </Card>
</template>
<script>
import Carousel from './celulas/Carousel.vue'
import Drop from './Drop.vue'
const axios = require("axios").default;
import folders from '../mixins/folders'
export default {
    mixins: [folders],
    components: {
        Carousel,
        Drop,
    },
    props: {
       
        carpeta_actual: {
            type: Number,
            required: true
        },
        carpetas: {
            type: Array,
            required: true,
        }
    },
    data(){
        return {
            datos_archivos: [],
            imagenes: [],

            files: [],
            indice: 1,
            ws: null,
            tipo_upload: null,

           
        }
    },
    watch: {
        tipo_upload(val){
            console.log(val)
            if(val=='Escaner'){
                this.getImages()
            }
        }
    },
    created(){
            let _this = this;
            const that = this;
            console.log('Holsssi')
            window.URL = window.URL || window.webkitURL;
            var wsImpl = window.WebSocket || window.MozWebSocket;

            this.ws = new wsImpl("ws://localhost:8181/");

            this.ws.onmessage = function (e) {
            console.log(e);
            try {
                if (e.data instanceof Blob) {
                //-----Estas son las funciones que debe ejecutar tu input----//
                that.seleccionarFoto(e.data);

                that.addFiles(e.data, that.$store.state.token, that.indice, "blob");
                //---------------------------------------------------------//
                }
            } catch (error) {
                console.log(error);
            }
            };

            this.ws.onopen = function () {
            console.log("Open ");
            console.log(that.indice);
            //btn_scan.removeAttribute('disabled');
            //download_app.style.display = 'none';
            };
            this.$store.commit("crearToken", 25);
            this.ws.onerror = function (e) {
            console.log(e);
            console.log("Error");
            _this.$store.commit(
                "error",
                "El programa SCAN APP no ha sido activado. Por favor inicielo en el escritorio e ingrese nuevamente a esta vista si desea ESCANEAR archivos, si ese no es el caso ignore este mensaje"
            );
            //btn_scan.setAttribute('disabled', '');
            //download_app.style.display = '';
            };
    },
    methods: {
        eliminar_archivo(indice){
            this.imagenes.splice(indice,1);
            this.datos_archivos.splice(indice,1)
            this.files.splice(indice,1)
        },
        async getImages() {
            console.log('xd')
            this.ws.send("1100");
            //this.scannerInicio = true;
        },
        async pruebaDrop(event) {
            console.log("Funcion prueba Drop");
            console.log(event);
            console.log(event.archivo);
            //this.scannerInicio = true;

            for (let index = 0; index < event.archivo.length; index++) {
                await this.addFiles(
                event.archivo[index],
                this.$store.state.token,
                event.obj[index].nombre,
                event.obj[index].tipo
                );
            }
            this.terminarProcesoArchivador();
            //Comentarios
            // this.archivo2 = event.archivo
            // this.objUpload2 = event.obj
        },
        seleccionarFoto(event) {
            console.log(event);
            var reader = new FileReader();

            reader.onload = (e) => {
                // Note: arrow function used here, so that "this.imageData" refers to the imageData of Vue component
                // Read image as base64 and set to imageData

                var result = e.target.result;

                this.imagenes.push(result);
            };

            reader.readAsDataURL(event);
        },
        async addFiles(blob, token, index, tipo) {
            //Se agrego un cuarto parametro llamado tipo el cual contendra
            //El tipo de archivo que se esta agregando .zip png jpg blob etc etc etc.
            //Con el fin de agregarlo al endpoint de almacenamiento y saber como debe hacerse la estructura de las url
            //Que permiten descargar los archivos la cual seria: http://url-endpoint/token/nombre.tipo
            let file = new FormData();
            console.log(token);
            file.append("file", blob);
            file.append("texto", token);
            file.append("nombre", index);

            console.log(blob);

            let obj = {
                nombre: index,
                token: token,
                tipo: tipo,
                autor: this.$store.state.dataUser.dataUser.nombre,
                sector: this.$store.state.dataUser.dataUser.sector,
                nombre_sector: this.$store.state.dataUser.dataUser.nombre_sector,
                fecha_adjuntado: Date.parse(new Date()), // Indicara la fecha cuando se adjunto la imagen
                meta_datos: this.$store.state.buscador.carpetas[this.carpeta_actual].meta_datos, //Parametros que asociaan a las imagenes a busquedas en las carpetas
                estado: 'habilitada'            
            };
            
            //obj = JSON.stringify(obj);

            this.datos_archivos.push(obj);

            this.files.push(file);
            this.indice++;
        },
        async uploadImg() {
            //var token = 'TokenPrueba2'
            this.$store.state.load = true;

            //Activar un spinner load

            for (let index = 0; index < this.files.length; index++) {
                try {
                this.contadorImagensSubidas++;
                this.$store.commit("auth");

                if (index + 1 == 1) {
                    //Upload docu crea la carpeta y anexa la primera imagen
                    var responseUploadImg = await axios.post(
                    "https://drive.educandote.co/audidoc/uploadDocu.php",
                    this.files[index],
                    {
                        header: {
                        "Content-Type": "multipart/form-data",
                        Authorization: this.$store.state.header,
                        },
                    }
                    );
                    console.log("Upload Imagen 1", responseUploadImg);
                } else {
                    //upgradeFolder.php anexa, datos al folder asociado al token

                    var response = await axios.post(
                    "https://drive.educandote.co/audidoc/upgradeFolder.php",
                    this.files[index],
                    {
                        header: {
                        "Content-Type": "multipart/form-data",
                        Authorization: this.$store.state.header,
                        },
                    }
                    );

                    console.log("Upload Imagen " + index);
                    console.log(response);
                }
                } catch (error) {
                //Posteriormente indicar cual sera el error en un dialog
                console.log(error);
                this.$store.state.load = false;
                return false; //Se retorna falso cuando existe un error en la subida de alguna imagen
                }
            }

                return true;
        },
        async terminarProcesoArchivador() {
           
        
            if (this.imagenes.length > 0 || this.datos_archivos.length > 0 ) {
                this.$store.state.dialogCargando = true
                var subidaImagenes = await this.uploadImg(); //Este upload permite subir todo ahora
                if (subidaImagenes) {
                    console.log("Terminando desde archivador");
                    try {

                        const url = "https://audidoc.educandote.co/types/collections/"+this.$store.state.buscador.coleccion_archivo_seleccionado+"/documents/"+ this.$store.state.buscador.archivo_seleccionado.id
                        const get_folders = await this.$store.dispatch('llamado_get',{
                            url: url,
                            tipo_header: "indexador"
                        });
                        console.log(get_folders.data);

                        let refresh_folders = await this.refresh_folders(get_folders.data.carpetas); //mixins folders.js
                        //let carpetas = [...get_folders.data.carpetas];

                        let obj = {
                            carpetas: [...refresh_folders]
                        }

                        console.log(obj.carpetas)
                        
                        console.log(obj.carpetas[this.carpeta_actual].archivos)
                        console.log(this.datos_archivos)
                        let todas_las_carpetas = [...obj.carpetas[this.carpeta_actual].archivos,...this.datos_archivos,]
                        obj.carpetas[this.carpeta_actual].archivos = [...todas_las_carpetas]
                        
                        for (let index = 0; index < obj.carpetas.length; index++) {
                           obj.carpetas[index] = JSON.stringify(obj.carpetas[index])
                        }
                        
                        console.log(obj)

                        //Hacer un get

                        let response = await this.$store.dispatch('llamado_patch',{
                            url:url,
                            body: obj,
                            tipo_header: "indexador"

                        })
                        //Enviamso el dato al endpoint asociado del tipo documental seleccionado
                        this.$store.state.buscador.carpetas[this.carpeta_actual].archivos = [...todas_las_carpetas]

                        console.log(this.$store.state.buscador.carpetas)
                        console.log(this.$store.state.buscador.carpetas[this.carpeta_actual])
                        this.$emit('cerrar')
                        console.log(response);

                        //
                        this.$store.commit('SET_CANTIDAD_DOCUMENTOS_SUBIDOS',this.imagenes.length)
                        this.imagenes = [];
                        this.files = [];
                        this.indice = 0;
                        this.datos_archivos = [];
                        this.contadorImagensSubidas = 0;
                        //this.reset();
                    
                        //this.scannerInicio = false;
                        this.$store.commit(
                        "exitoso",
                        "Los archivos han sido agregados exitosamente"
                        );
                        this.$store.state.load = false;
                        this.$store.commit("crearToken", 25);
                    } catch (error) {
                        this.$store.commit(
                        "error",
                        "Ha ocurrido un error inesperado en el momento de almacenar los datos en la coleccion "+error.message
                        );
                        console.log(error);
                    }
                }
            } else {
                this.$store.commit(
                "error",
                "Por favor escanea algunas imagenes antes de enviar los datos"
                );
            }
            this.$store.state.dialogCargando = false
        },
    },
}
</script>