<template>
      <v-window v-model="step">
        <v-window-item :value="1">
          <v-card v-on:keydown.enter="buscar()" >
          <v-row>
            <v-col cols="">
              <v-container fluid>
                <v-sheet elevation="5" class="pa-4">
                  <v-progress-linear
                      color="deep-purple accent-4"
                      indeterminate
                      rounded
                      height="6"
                      v-show="load"
                    ></v-progress-linear>
                   
                    <v-toolbar rounded="" col
                    or="indigo darken-4" dark>
                      <v-toolbar-title class="headline font-weight-light">
                        Buscadores
                      </v-toolbar-title>
                    <v-spacer></v-spacer>
                     <slot name="accion"></slot>
                    </v-toolbar>
                  <v-container>
                    <v-row align="center" justify="center">
                      <v-col cols="">
                        <div
                          v-text="
                            'Selecciona un tipo de filtro por el cual deseas buscar un documentos posteriormente, coloca su descripcion'
                          "
                          class="subtitle-1 font-weight-light"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                     
                      <v-col>
                        <v-autocomplete
                          @change="tipoFiltro"
                          prepend-icon="mdi-clipboard-text-outline"
                          rounded
                          filled
                          dense
                          v-model="tipo_documental_seleccionado"
                          label="Escoge el tipo documental"
                          :items="tipos_documentales"
                        />
                      </v-col>
                      <v-col cols="">
                        <v-autocomplete
                          rounded
                          filled
                          dense
                          prepend-icon="mdi-clipboard-text-outline"
                          v-model="campo_seleccionado"
                          label="Buscar por: "
                          :items="campos_tipo_documental"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="">
                        <v-text-field
                          rounded
                          filled
                          dense
                          v-model="parametro"
                          label="Ingresa el dato a buscar"
                          append-icon="mdi-magnify"
                          @click:append="buscar()"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                  <v-container fluid v-if="notFound == true">
                    <v-row align="center" justify="center">
                      <div
                        v-text="'No existen coincidencias en la busqueda '+error.message  "
                        class="title font-weight-light"
                      ></div>
                    </v-row>
                    <v-row class="mx-auto">
                    
                        <!--  -->
                        <img
                          :src="require('../assets/notFound.png')"
                          class="mx-auto"
                          alt="Datos no encontrados"
                          contain
                          width=300
                        />
                    
                    </v-row>
                  </v-container>
                 
                </v-sheet>
              </v-container>
            </v-col>
          </v-row>
        </v-card>
        </v-window-item>
        <v-window-item :value="2">
          <v-sheet>
            <Toolbar :activar_tool="false" :titulo="'Resultados...'">
                <template v-slot:accion_retroceder >
                    <v-btn dark icon @click="step--"><v-icon>mdi-arrow-left</v-icon></v-btn>
                </template>
            </Toolbar>
            <Paginacion :cantidad_paginas="parseInt(cantidad_paginas)" class="my-3" @buscar="page=$event" />
                <v-data-table
                  :headers="headers"
                  :items="desserts"
                  class="mx-10"
                  max-width="650"
                  :search="search"
                  :items-per-page="250"
                  hide-default-footer
                  :page.sync="page"
                
                >
                  <template v-slot:top>
                    <v-toolbar
                      flat
                    >
                    <v-toolbar-title> Resultados </v-toolbar-title>
                      <v-divider
                        class="mx-4"
                        inset
                        vertical
                      ></v-divider>
                      <v-spacer></v-spacer>
                      <v-col cols="12" sm="4">
                        <v-text-field :append-outer-icon="'mdi-magnify'"  label="Buscar" v-model="search"></v-text-field>
                      </v-col>
                
                    </v-toolbar>
                  </template>
                  <template v-slot:item.datos_archivos="{item}">
                     <v-btn
                            color="indigo"
                            dark
                            class="mb-2"
                           
                            icon
                            @click="revisar_archivo_seleccionado(item)"
                          >
                            <v-icon> mdi-folder-information-outline</v-icon>
                  </v-btn>
                  </template>
                  <template v-slot:item.actions="{item}">
                        <v-btn
                            color="green"
                            dark
                            class="mb-2"
                           
                            icon
                            @click="editar_inputs(item)"
                          >
                          <v-icon> mdi-pencil </v-icon>
                      </v-btn>
                  </template>
                </v-data-table>
          
            </v-sheet>
             <v-dialog
                v-model="dialogo_revisar_archivo_seleccionado"
                fullscreen
              >
                <Card :titulo="'Revisar archivo seleccionado'" @activar="cerrar_revision_archivos()" >
                  <template v-slot:contenido >
                    <RevisarArchivos />
                  </template>
                </Card>
                        
                        
             </v-dialog>
             <v-dialog v-model="dialogo_editar" max-width="600" persistent>
               <Card :load="load_editar" :titulo="'Editar informacion'" @activar="dialogo_editar=false">
                 <template v-slot:contenido>
                   <v-row class="mt-2" v-for="(input,index) in headers_editar" :key="index">
                     <v-text-field prepend-icon="mdi-book" filled rounded dense :label="input.text" v-model="informacion_editar[input.value]" ></v-text-field>
                   </v-row>
                   <v-row>
                     <v-btn @click="editar_informacion_endpoint(headers_editar,informacion_editar)" block text color="green"> Editar </v-btn>
                   </v-row>
                 </template>
               </Card>
             </v-dialog>
             
        </v-window-item>
      </v-window>
</template>
<script>
const axios = require("axios").default;
import RevisarArchivos from './celulas/RevisarArchivos.vue'
import {EventBus} from '../event/event-bus'
//import NotFound from "../components/NotFound";
import folders from '../mixins/folders'
export default {
  mixins: [folders],
  components: {
    //Carousel,
    //NotFound,
    RevisarArchivos,
    
  },
  data() {
    return {

      dialogo_agregar: false,

      step: 1,
     
      filtro: "",

      tipoFiltroSelect: "",
      resultados: [],
    
     
      dialogo_editar: false,
      informacion_editar: {},
      headers_editar: [],
      load_editar: false,

      headers: [],
      desserts: [],
      cantidad_paginas: 1,
      page: 1,
      search: '',
     
      items: [],
      
      load: false,
      notFound: false,

      //Variables agregadas por fry
      nombre_endpoint_almacenamiento: '',
      campo_seleccionado: '',
      tipo_documental_seleccionado: '',
      tipos_documentales: [],
      campos_tipo_documental: [],
      tipos_documentales_completo: [],
      parametro: '',
      archivos: [],
      error: {
        message: ''
      },
      dialogo_revisar_archivo_seleccionado: false,

      //datos_adjuntos: [],
      //formularios: [],
     
      coleccion: "",
      archivo_seleccionado: {},
      
      
    };
  },
  watch: {
    page(val){
      console.log(val)
      this.buscar()
    }
  },
  async created() {
     this.consultar_tipos_documentales()

  },
  methods: {
    cerrar_revision_archivos(){
      console.log('xsdss')
      this.dialogo_revisar_archivo_seleccionado = false;
      EventBus.$emit('cambiar_carpeta',0) //Este evento se emite para ir a la caprta inicial de "revusar Archivos"
    },
       
    actualizar_archivos(informacion){
      console.log(informacion)
      console.log(this.archivo_seleccionado)
      let index = this.desserts.indexOf(this.archivo_seleccionado)
      console.log(index)
      Object.assign(this.desserts[index], informacion)
      console.log(this.desserts)
    },
    async editar_informacion_endpoint(body,informacion){
      console.log(informacion)
      this.load_editar = true
      try {
        let obj = {}
        for (let index = 0; index < body.length; index++) {
         
          obj[body[index].value] = informacion[body[index].value]
        }
        console.log(obj)
        
        let coleccion = 'prueba'+this.tipo_documental_seleccionado.replace(/ /g, "")
        let response = await this.$store.dispatch('llamado_patch',{
          url:"https://audidoc.educandote.co/types/collections/"+coleccion+"/documents/"+informacion.id,
          body: obj,
          tipo_header: "indexador"

        })

        let informar_edicion = await this.$store.dispatch('llamado_post',{
          url:"https://audidoc.educandote.co/parse/classes/prueba01Modificaciones",
          body: {
            usuario: this.$store.state.dataUser.dataUser.objectId,
            coleccion: coleccion,
            id_objeto: informacion.id,
            objeto_editado: this.desserts[informacion.index_editar],
            objeto_nuevo: obj,
          },
          tipo_header: "parse"
        })
        console.log(informar_edicion)

        Object.assign(this.desserts[informacion.index_editar], informacion)
        console.log(response)

       this.$store.commit(
          "exitoso",
          "Los campos han sido editados de forma exitosa..."
        );

      } catch (error) {
        console.log(error)
        this.$store.commit(
          "error",
          "Ha ocurrido un error inesperado al momento de editar los campos... "+error.message
        );
      }
      this.dialogo_editar = false
      this.load_editar = false
    },

    editar_inputs(item){
      console.log(item)
      let index = this.desserts.indexOf(item)
      this.informacion_editar = {...item, index_editar: index }
      this.dialogo_editar = true
    },
    async revisar_archivo_seleccionado(item){
      console.log(item)
      this.$store.state.buscador.datos_adjuntos = []
      this.$store.state.buscador.formularios = []
      this.$store.state.buscador.carpetas = []
      this.$store.state.buscador.archivo_seleccionado = item
      this.$store.state.buscador.coleccion_archivo_seleccionado = 'prueba'+this.tipo_documental_seleccionado.replace(/ /g, "")
      let info
      console.log(item.datos_archivos)
      for (let index = 0; index < item.datos_archivos.length; index++) {
        info = JSON.parse(item.datos_archivos[index])
        if(info.tipo!='Formulario'){
           this.$store.state.buscador.datos_adjuntos.push(info)
        }else{
          this.$store.state.buscador.formularios.push(info)
        }
      }

      let refresh_folders = await this.refresh_folders(item.carpetas); //mixins folders.js
      this.$store.state.buscador.carpetas = [...refresh_folders];
     

      console.log(this.$store.state.buscador)
      console.log('xsdss')
      console.log(this.$store.state.buscador.carpetas[0].archivos)
      console.log(this.$store.state.buscador.carpetas[0].nombre)

      console.log(this.$store.state.buscador.datos_adjuntos)
      console.log(this.$store.state.buscador.formularios)
      this.dialogo_revisar_archivo_seleccionado = true
    },
    revisar(item){
      console.log(item)
      let aux2
      this.headers = [
        {value: 'actions',text:'Editar'}
      ]
      this.headers_editar  = []
      Object.keys(item).forEach( (key) => {
          console.log(key)
          aux2 = key.replace(/_/g, " ")
        
          if(key!='fecha_creacion' && key!='carpetas' ){
              
              this.headers.push({
                value: key,
                text: aux2,
              })
          }
          if(key!='id' && key!='fecha_creacion' && key!='datos_archivos' && key!='carpetas' ){
            this.headers_editar.push({
              value: key,
              text: aux2,
            })
          }
          
        })
       
      
    },
  
    async consultar_tipos_documentales(){
           
        this.$store.commit('auth','parse')
        let response = await axios.get('https://audidoc.educandote.co/parse/classes/prueba01Sector/'+this.$store.state.dataUser.dataUser.sector,
        {
            headers:{
                    'Content-Type' : 'application/json',
                    'X-Parse-REST-API-Key': 'LASDK823JKHR87SDFJSDHF8DFHASFDF',
                    'X-Parse-Application-Id': 'KSDJFKASJFI3S8DSJFDH',
                    'Authorization': this.$store.state.header
                }
        })
        

        let tipos_documentales = JSON.parse(response.data.backup_tipos_documentales);

        tipos_documentales = tipos_documentales.tipos_documentales;

        console.log(tipos_documentales);

        for (let index = 0; index < tipos_documentales.length; index++) {
            this.tipos_documentales.push(tipos_documentales[index].nombre)
        }
        this.tipos_documentales_completo = tipos_documentales;
        

    },
    tipoFiltro(){
           console.log(this.tipo_documental_seleccionado)
            this.campos_tipo_documental = []
            let aux = this.tipos_documentales_completo.filter( item => item.nombre.toLowerCase().indexOf(this.tipo_documental_seleccionado.toLowerCase()) !== -1 )
            console.log(aux)
            this.nombre_endpoint_almacenamiento = aux[0].nombre_endpoint_almacenamiento
            for (let index = 0; index < aux[0].parametros.length; index++) {
               this.campos_tipo_documental.push(aux[0].parametros[index].name)
            }
            this.campos_tipo_documental.push('Nombre o identificacion del documento') 
    },
    buscar(){
            this.load = true
            let _this = this
            //replace(/ /g, "")
            let coleccion = 'prueba'+this.tipo_documental_seleccionado.replace(/ /g, "")
            //coleccion = 'pruebaExpedientedecontribuyentepredial'
            let  campo = this.campo_seleccionado.replace(/ /g, "_")
            //campo = 'Referencia_catastral'
             _this.desserts = []
             axios.get("https://audidoc.educandote.co/types/collections/"+coleccion+"/documents/search?q="+this.parametro+"&query_by="+campo+"&filter_by="+campo+":["+this.parametro+"]&per_page=250&page="+this.page,
            {
                headers:{
                        'Content-Type' : 'application/json',
                        'X-TYPESENSE-API-KEY': 'S9cLrE7dFV9T6HZ63PScpOaAIoBp7NHLFrdHoLN3RriOpyZe'
                    }
            }).then(function(response){

                console.log(response)

                if(response.data.found>=1){

                   _this.revisar(response.data.hits[0].document)
                    for (let index = 0; index < response.data.hits.length; index++) {
                        
                        _this.desserts.push(response.data.hits[index].document)
                       
                         console.log(response.data.hits[index].document)
                        
                    }
                    console.log(_this.desserts)
                    console.log(_this.headers)
                    _this.step= 2
                   _this.notFound = false
                   _this.cantidad_paginas = parseInt(response.data.found/250)+1
                }else{
                  _this.notFound = true
                }
                 _this.load=false

            }).catch(function(error){
                console.log(error)
                _this.error = error
                _this.notFound = true
                _this.load=false
            })
    }
  },
};
</script>