<template>
    <v-sheet>
        <Toolbar 
            :titulo="'Carpetas'" 
            :activar_tool="true" 
            :accion="'Crear carpeta'" 
            :icono="'mdi-folder-multiple-plus-outline'"
            @activar="$emit('crear_carpeta')" 
        />
        <v-text-field
            v-model="busar_carpeta"
            filled rounded dense
            label="Buscar expediente"
        ></v-text-field>
        <v-virtual-scroll
            :bench="0"
            :items="carpeta_filtradas"
            height="650"
            item-height="64"
        >
            <template v-slot:default="{ item }">
                
                    <v-list-item v-if="item.estado=='habilitada'"  @click="$emit('seleccion',item)">
                        <v-list-item-avatar>
                            <v-icon :color="color(item)" class="grey lighten-1" dark >mdi-folder</v-icon>
                        </v-list-item-avatar>

                        <v-list-item-content>
                            <v-list-item-title v-text="item.nombre"></v-list-item-title>

                        </v-list-item-content>

                        <v-list-item-action>
                        <v-btn icon>
                            <v-icon color="green lighten-1">mdi-information</v-icon>
                        </v-btn>
                        </v-list-item-action>
                    </v-list-item>
                    <v-list-item v-else>
                        <v-list-item-avatar>
                            <v-icon :color="'red'" dark >mdi-folder</v-icon>
                        </v-list-item-avatar>

                        <v-list-item-content>
                            <v-list-item-title v-text="'Eliminada '+item.nombre"></v-list-item-title>

                        </v-list-item-content>

                        <v-list-item-action>
                        <v-btn icon>
                            <v-icon color="red lighten-1">mdi-information</v-icon>
                        </v-btn>
                        </v-list-item-action>
                    </v-list-item>
                <v-divider></v-divider>
            </template>
        </v-virtual-scroll>
    </v-sheet>
</template>
<script>
import { mapState } from 'vuex';
export default {
    props: {
       
    },
    data(){
        return {
            busar_carpeta: "",
            carpeta_filtradas: [],
        }
    },
    watch: {
        carpetas(val){
            
          
            this.carpeta_filtradas = [...val]
        },
        busar_carpeta(val){
            val = val.toLowerCase()
            this.carpeta_filtradas = this.carpetas.filter( item => item.nombre.toLowerCase().indexOf(val) !== -1 )
        },
    },
    created(){
        this.carpeta_filtradas = [...this.carpetas]
    },
    methods: {
        color(item){
            if(typeof item.estado!='undefined' )
            console.log(item)
            return 'yellow'
        }
    },
    computed: mapState('buscador',['carpetas'])
    
    
}
</script>